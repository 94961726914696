<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">人员数据汇集</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="任务名称" class="searchboxItem ci-full">
              <span class="itemLabel">任务名称:</span>
              <el-input v-model="retrievalDatas.integrationName" clearable size="small" placeholder="请输入任务名称"></el-input>
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="retrievalDatas.occupationId" placeholder="请选择培训工种" clearable size="small">
                <el-option v-for="item in occupationList" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                  <span style="float: left">{{ item.occupationName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.trainLevel }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel">创建日期:</span>
              <el-date-picker v-model="retrievalDatas.createTime" size="small" type="daterange" style="width: 4.5rem"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd"
                value-format="yyyy-MM-dd" />
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="addData()">创建</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="任务名称" align="left" show-overflow-tooltip prop="integrationName" min-width="150" />
              <el-table-column label="培训种类" align="left" show-overflow-tooltip prop="occupationName" min-width="150" />
              <el-table-column label="报名任务名称" align="left" show-overflow-tooltip prop="projectName" min-width="150" />
              <el-table-column label="考务名称" align="left" show-overflow-tooltip prop="activityName" min-width="150" />
              <el-table-column label="制证任务名称" align="left" show-overflow-tooltip prop="taskExplain" min-width="150" />
              <el-table-column label="汇集人数" align="left" show-overflow-tooltip prop="personNum" width="100" />
              <el-table-column label="创建日期" align="left" show-overflow-tooltip prop="createTime" width="150" />
              <el-table-column label="操作" align="center" show-overflow-tooltip width="160" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="seeStudent(scope.row)">查看学员</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="downloadData(scope.row)">下载</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="delData(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate_personnelDataCollectionJiLin_index",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        integrationName:"", // 任务名称
        occupationId: '', // 培训工种
        createTime: [], // 创建日期
      },
      // 培训工种 - 下拉数据
      occupationList: [],
    };
  },
  created() {
    this.searchCompOccupation();
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取 - 培训工种
    searchCompOccupation() {
      this.$post('/biz/ct/occupation/select/pageList', {
        occupationName: ""
      }).then(ret => {
        this.occupationList = ret.data || []
      }).catch(err => {
        return;
      })
    },
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalDatas.integrationName) {
        params.integrationName = this.retrievalDatas.integrationName;
      }
      if (this.retrievalDatas.occupationId) {
        params.occupationId = this.retrievalDatas.occupationId;
      }
      if (this.retrievalDatas.createTime) {
        params.startTime = this.retrievalDatas.createTime[0];
        params.endTime = this.retrievalDatas.createTime[1];
      }
      
      this.doFetch({
        url: "/biz/ct/datacenter/employeeAmountPageListJL",
        params,
        pageNum
      });
    },
    // 新增
    addData(){
      this.$router.push({
        path: "/operate/personnelDataCollectionJiLin/addData"
      });
    },
    // 列表 - 查看学员
    seeStudent(row) {
      this.$router.push({
        path: "/operate/personnelDataCollectionJiLin/studentList",
        query: {
          integrationId: row.integrationId
        }
      });
    },
    // 列表 - 下载
    downloadData(row) {
      this.$post("/biz/ct/datacenter/employeeListJLExport", {
        integrationId: row.integrationId,
      }).then((res) => {
        const arr = [...res.data];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(
              "[" + item.fileName + "]已经申请下载,请耐心等待"
            );
          }
        }
      })
        .catch(() => {
          return;
        });
    },
    // 列表 - 删除
    delData(row) {
      this.doDel({
        url: "/biz/ct/integration/delete",
        msg: "你确定要删除该任务吗？",
        ps: {
          type: "post",
          data: { integrationId: row.integrationId },
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (from.path == '/operate/personnelDataCollectionJiLin/addData' || from.path == '/operate/personnelDataCollectionJiLin/studentList') {
        vm.getData(vm.pageNum)
      }
    })
  },
  watch: {

  }
};
</script>
<style lang="less" scoped></style>
